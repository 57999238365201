


















































































import { ref } from '@vue/composition-api';
import Module from '@/types/Module';
import api from '@/api';
import ModuleDisplay from '@/components/ModuleDisplay.vue';

export default {
  name: 'AdminModules',
  components: {
    ModuleDisplay,
  },
  setup(props, context) {
    const modules = ref<Module[] | null>(null);
    const editModule = ref<Module | null>(null);

    const getModules = () => {
      api.getModules()
        .then((response) => {
          modules.value = response.data;
        });
    };

    const updateModule = (module: Module) => {
      api.updateModule(module)
        .then(() => {
          context.root.$bvToast.toast(`Le module ${module.name} a été mis à jour.`, {
            variant: 'success',
          });
          getModules();
        })
        .catch(() => {
          context.root.$bvToast.toast('Une erreur est survenue!', {
            variant: 'danger',
          });
        });
    };

    const removeModule = (module: Module) => {
      context.root.$bvModal.msgBoxConfirm(`Tu es sûr de vouloir supprimer le module ${module.name}?`)
        .then((value: boolean) => {
          if (value === true) {
            api.deleteModule(module.id);
            getModules();
          }
        })
        .catch(() => {
          context.root.$bvToast.toast('Une erreur est survenue!', {
            variant: 'danger',
          });
        });
    };

    getModules();

    return {
      modules,
      editModule,

      getModules,
      updateModule,
      removeModule,
    };
  },
};
