







































































































import { ref, computed } from '@vue/composition-api';
import { ModuleDetails } from '@/types/Module';
import api from '@/api';
import Lesson from '@/types/Lesson';

export default {
  name: 'AdminLessons',
  metaInfo: {
    title: 'Leçons',
  },
  props: {
    moduleId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const moduleInfo = ref<ModuleDetails | null>(null);
    const editLesson = ref<Lesson | null>(null);

    const breadcrumb = computed(() => [
      {
        text: 'Modules',
        to: { name: 'admin-modules' },
      },
      {
        text: moduleInfo.value?.module.name,
        to: { name: 'admin-modules' },
      },
      {
        text: 'Leçons',
        active: true,
      },
    ]);

    const getModule = () => {
      api.getModule(props.moduleId as number)
        .then((response) => {
          moduleInfo.value = response.data;
        });
    };

    const updateLesson = (lesson: Lesson) => {
      api.updateLesson(lesson)
        .then(() => {
          context.root.$bvToast.toast(`Le module ${lesson.title} a été mis à jour.`, {
            variant: 'success',
          });
          getModule();
        })
        .catch(() => {
          context.root.$bvToast.toast('Une erreur est survenue!', {
            variant: 'danger',
          });
        });
    };

    const removeLesson = (lesson: Lesson) => {
      context.root.$bvModal.msgBoxConfirm(`Tu es sûr de vouloir supprimer la leçon ${lesson.title}?`)
        .then((value: boolean) => {
          if (value === true) {
            api.deleteLesson(lesson.slug);
            getModule();
          }
        })
        .catch(() => {
          context.root.$bvToast.toast('Une erreur est survenue!', {
            variant: 'danger',
          });
        });
    };

    getModule();

    return {
      moduleInfo,
      breadcrumb,
      editLesson,

      updateLesson,
      removeLesson,
    };
  },
};
