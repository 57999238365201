<template>
  <section class="container">
    <p class="mt-8">
      Déconnexion en cours...
    </p>
  </section>
</template>

<script>
import { useRouter } from 'vue2-helpers/vue-router';
import api from '@/api';
import useStore from '@/store';

export default {
  name: 'Logout',
  metaInfo: {
    title: 'Déconnexion',
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    api.logout()
      .then(() => {
        store.logout();
        router.push({ name: 'login' });
      });
  },
};
</script>
