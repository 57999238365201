




























































import {
  defineComponent,
  reactive,
  ref,
  watch,
} from '@vue/composition-api';
import { useRouter } from 'vue2-helpers/vue-router';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import api from '@/api';

export default defineComponent({
  name: 'AdminLessonCreate',
  metaInfo: {
    title: 'Nouvelle leçon',
  },
  components: {
    VuePdfEmbed,
  },
  props: {
    moduleId: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const router = useRouter();
    const lesson = reactive({
      title: '',
      description: '',
      priority: 1,
    });
    const pdf = ref<File | null>(null);
    const pdfContent = ref<{ data: ArrayBuffer } | null>(null);

    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target?.result) {
        pdfContent.value = {
          data: e.target.result as ArrayBuffer,
        };
      }
    };

    if (!props.moduleId) {
      router.push({ name: 'admin-modules' });
      return {};
    }

    const createLesson = () => {
      if (!lesson.title || !pdf.value) return;

      const form = new FormData();
      form.append('title', lesson.title);
      form.append('description', lesson.description);
      form.append('priority', lesson.priority.toString(10));
      form.append('pdf', pdf.value);
      form.append('module_id', props.moduleId);

      api.createLesson(form)
        .then(() => {
          context.root.$bvToast.toast(`La leçon ${lesson.title} a été créé.`, {
            variant: 'success',
          });

          router.push({
            name: 'admin-lessons',
            params: {
              moduleId: props.moduleId as string,
            },
          });
        })
        .catch(() => {
          context.root.$bvToast.toast('Une erreur est survenue! Veuillez vérifier les champs du formulaire.', {
            variant: 'danger',
          });
        });
    };

    watch(pdf, () => {
      if (pdf.value) {
        reader.readAsArrayBuffer(pdf.value);
      }
    }, { deep: true });

    return {
      lesson,
      pdf,
      pdfContent,

      createLesson,
    };
  },
});
