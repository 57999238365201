














import { ref } from '@vue/composition-api';
import api from '@/api';
import useStore from '@/store';

import TheHeader from './components/TheHeader.vue';
import TheFooter from './components/TheFooter.vue';
import { start } from './router';

export default {
  components: {
    TheHeader,
    TheFooter,
  },
  metaInfo: {
    titleTemplate: '%s - ImmoSuccès',
    title: 'Accueil',
  },
  setup() {
    const store = useStore();

    const isBusy = ref(true);

    api.me()
      .then((response) => {
        store.login(response.data);
      })
      .catch(store.logout)
      .finally(() => {
        start(null);
        isBusy.value = false;
      });

    return {
      isBusy,
    };
  },
};
