<template>
  <img src="@/assets/images/ImmoSucces.svg" class="h-100">
</template>

<script>
export default {
  name: 'LogoDynamic'
}
</script>

<style scoped>

</style>
