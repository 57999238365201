<template>
  <b-navbar toggleable="lg" type="light" variant="yellow" fixed="top">
    <b-navbar-nav class="w-lg-33">
      <b-nav-item class="d-none d-lg-block" :to="{ name: 'index' }">
        Accueil
      </b-nav-item>
    </b-navbar-nav>

    <b-navbar-brand
      :to="{ name: 'index' }"
      class="d-flex justify-content-center mr-0 py-0 w-lg-33"
      style="height: 44px"
      >
      <logo />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" />

    <b-collapse id="nav-collapse" class="w-lg-33" is-nav>
      <b-navbar-nav class="ml-auto">
        <template v-if="!store.user">
          <b-nav-item :to="{ name: 'login' }">
            Connexion
          </b-nav-item>
          <b-nav-item :to="{ name: 'register' }">
            Inscription
          </b-nav-item>
        </template>

        <template v-else>
          <b-nav-item v-if="store.user.subscription.is_subscribed" :to="{ name: 'modules' }">
            Modules
          </b-nav-item>
          <b-nav-item v-if="store.user.is_admin" :to="{ name: 'admin-modules' }">
            Administration
          </b-nav-item>
          <b-nav-item :to="{ name: 'profile' }">
            Profil
          </b-nav-item>
          <b-nav-item :to="{ name: 'logout' }">
            Déconnexion
          </b-nav-item>
        </template>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import Logo from './Logo.vue';
import useStore from '@/store';

export default {
  name: 'TheHeader',
  components: {
    Logo,
  },
  setup() {
    const store = useStore();

    return {
      store,
    };
  },
};
</script>

<style scoped>
@media (min-width: 992px) {
  .w-lg-33 {
    width: 33.3333%;
  }
}
</style>
