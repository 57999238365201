
















































import { computed, defineComponent, ref } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { required, email as emailValidator, minLength } from '@vuelidate/validators';
import { useRouter } from 'vue2-helpers/vue-router';

import api from '@/api';
import useStore from '@/store';

export default defineComponent({
  name: 'Login',
  metaInfo: {
    title: 'Connexion',
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();

    const isBusy = ref(false);
    const email = ref('');
    const password = ref('');
    const rememberMe = ref(false);

    const validations = computed(() => ({
      email: {
        required,
        email: emailValidator,
      },
      password: {
        minLength: minLength(8),
      },
    }));
    const $v = useVuelidate(validations, { email, password });

    const login = () => {
      $v.value.$touch();
      if ($v.value.$invalid) return;
      isBusy.value = true;

      api.login(email.value, password.value)
        .then(() => {
          api.me()
            .then((response) => {
              store.login(response.data);
              router.push({ name: store.user?.subscription.is_subscribed ? 'modules' : 'index' });
            })
            .catch(() => {
              context.root.$bvToast.toast('Une erreur inconnue est survenue, veuillez réessayer plus tard.', {
                title: 'Connexion impossible',
                variant: 'danger',
              });
            })
            .finally(() => { isBusy.value = false; });
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.message.includes('The given data was invalid')) {
            context.root.$bvToast.toast('Aucun compte ne correspond à ces identifiants.', {
              title: 'Identifiants incorrects',
              variant: 'danger',
            });
          }
          isBusy.value = false;
        });
    };

    return {
      $v,
      isBusy,
      email,
      password,
      rememberMe,

      login,
    };
  },
});
