











import { defineComponent, ref } from "@vue/composition-api";
import { useRouter } from "vue2-helpers/vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();

    const redirectIn = ref(5);
    let interval: number;

    const startCountdown = () => {
      interval = setInterval(() => {
        redirectIn.value--;

        if (redirectIn.value <= 0) {
          clearInterval(interval);

          router.push({
            name: 'profile',
          });
        }
      }, 1000);
    };

    startCountdown();

    return {
      redirectIn,
    };
  }
});
