




















































import { ref } from '@vue/composition-api';
import { useRouter } from 'vue2-helpers/vue-router';
import api from '@/api';
import ModuleDisplay from '@/components/ModuleDisplay.vue';

export default {
  name: 'AdminModuleCreate',
  metaInfo: {
    title: 'Nouveau module',
  },
  components: {
    ModuleDisplay,
  },
  setup(props, context) {
    const router = useRouter();
    const module = ref({
      name: '',
      description: '',
      icon: 'fa-',
    });

    const createModule = () => {
      api.createModule(module.value)
        .then(() => {
          context.root.$bvToast.toast(`Le module ${module.value.name} a été créé.`, {
            variant: 'success',
          });

          router.push({ name: 'admin-modules' });
        })
        .catch(() => {
          context.root.$bvToast.toast('Une erreur est survenue! Veuillez vérifier les champs du formulaire.', {
            variant: 'danger',
          });
        });
    };

    return {
      module,

      createModule,
    };
  },
};
