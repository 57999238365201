






























import { defineComponent, ref } from '@vue/composition-api';
import { useRouter } from 'vue2-helpers/vue-router';

import api from '@/api';
import ModuleDisplay from '@/components/ModuleDisplay.vue';
import Module from '@/types/Module';

export default defineComponent({
  name: 'Modules',
  metaInfo: {
    title: 'Notions',
  },
  components: {
    ModuleDisplay,
  },
  setup(props, context) {
    const router = useRouter();

    const modules = ref<Module[]>([]);

    const getModules = () => {
      api.getModules()
        .then((response) => {
          modules.value = response.data;
        })
        .catch(() => {
          context.root.$bvToast.toast('Une erreur est survenue.');
        });
    };

    const redirectToLessons = (id: number) => {
      router.push({
        name: 'lessons',
        params: {
          moduleId: id.toString(10),
        },
      });
    };

    getModules();

    return {
      modules,

      redirectToLessons,
    };
  },
});
