import { defineStore } from 'pinia';
import User from '@/types/User';

const useStore = defineStore('main', {
  state: () => ({
    user: null as User | null,
  }),

  actions: {
    login(user: User) {
      this.user = user;
    },

    logout() {
      this.user = null;
    },
  },
});

export default useStore;
