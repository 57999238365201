<template>
  <main class="full-height container mt-8">
    <div class="row align-items-center">
      <form class="col-12 col-md-6" @submit.prevent="register">
        <h1 class="font-weight-normal mb-4">
          Inscription
        </h1>

        <p class="mb-3">
          Vous avez déjà un compte?
          <router-link :to="{ name: 'login' }" class="pl-1 text-secondary">
            Connectez-vous
          </router-link>
        </p>

        <b-form-group label="Nom complet" label-for="name-input">
          <b-form-input
            id="name-input"
            v-model="user.name"
            name="name"
            type="email"
          />
        </b-form-group>

        <b-form-group label="Adresse mail" label-for="email-input">
          <b-form-input
            id="email-input"
            v-model="user.email"
            name="email"
            type="email"
          />
        </b-form-group>

        <b-form-group label="Mot de passe" label-for="password-input">
          <b-form-input
            id="password-input"
            v-model="user.password"
            name="password"
            type="password"
          />
        </b-form-group>

        <b-form-group label="Confirmer le mot de passe" label-for="password-confirm">
          <b-form-input
            id="password-confirm"
            v-model="user.passwordConfirm"
            name="password"
            type="password"
          />
        </b-form-group>

        <button class="btn btn-primary w-50 mt-4" @click.prevent="register">
          <i v-if="isBusy" class="fas fa-spinner fa-spin" />
          S'inscrire
        </button>
      </form>

      <img
        src="@/assets/images/Logo_ImmoSuccess_Text.png"
        alt="Logo ImmoSuccès"
        class="col-6 d-none d-md-block"
        >
    </div>
  </main>
</template>

<script>
import {
  ref,
  reactive,
  getCurrentInstance,
  computed,
  defineComponent,
} from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import { useRouter } from 'vue2-helpers/vue-router';
import {
  required,
  email,
  minLength,
  maxLength,
  sameAs,
} from '@vuelidate/validators';

import useStore from '@/store';
import api from '@/api';

export default defineComponent({
  name: 'Register',
  metaInfo: {
    title: 'Inscription',
  },
  validations() {
    return {
      user: {
        name: {
          required,
          maxLength: maxLength(100),
        },
        email: {
          required,
          email,
          maxLength: maxLength(200),
        },
        password: {
          required,
          minLength: minLength(8),
        },
        passwordConfirm: {
          required,
          sameAs: sameAs(this.user.password),
        },
      },
    };
  },
  setup(props, context) {
    const store = useStore();
    const $v = useVuelidate();
    const router = useRouter();

    const currentInstance = getCurrentInstance();
    const route = computed(() => currentInstance.proxy.$route);

    const isBusy = ref(false);
    const user = reactive({
      name: '',
      email: '',
      password: '',
      passwordConfirm: '',
    });

    const register = () => {
      $v.value.$touch();
      if ($v.value.$invalid) return;

      isBusy.value = true;

      api.register({
        name: user.name,
        email: user.email,
        password: user.password,
        password_confirmation: user.passwordConfirm,
      })
        .then(() => {
          api.me()
            .then((response) => {
              store.login(response.data);

              const { plan } = route.value.query;
              if (plan) {
                api.createSubscription(plan)
                  .then((res) => {
                    window.location.href = res.data.url;
                  });
              } else {
                router.push({ name: 'profile' });
              }
            });
        })
        .catch(() => {
          context.root.$bvToast.toast('Une erreur est survenue, veuillez réessayer plus tard.', {
            variant: 'danger',
            title: 'Une erreur est survenue',
          });
          isBusy.value = false;
        });
    };

    return {
      isBusy,
      user,
      $v,

      register,
    };
  },
});
</script>

<style scoped>
.container.full-height {
  min-height: calc(100vh - 8rem);

  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
