<template>
  <div class="col-12 col-md-6 col-lg mb-4 mb-lg-0">
    <div class="bg-yellow text-white square-icon mb-2">
      <i class="fas fa-lg" :class="icon" />
    </div>

    <h5 class="font-weight-bold mb-1">
      {{ title }}
    </h5>
    <p class="mb-0">
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'fa-money-bill-wave',
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
.square-icon {
  height: 64px;
  width: 64px;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
