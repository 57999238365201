



































import { defineComponent, ref } from '@vue/composition-api';
import { useRouter } from 'vue2-helpers/vue-router';
import { ModuleDetails } from '@/types/Module';
import api from '@/api';

export default defineComponent({
  name: 'Lessons',
  metaInfo: {
    title: 'Leçons',
  },
  props: {
    moduleId: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();

    if (!props.moduleId) {
      router.push({ name: 'modules' });
    }

    const moduleInfo = ref<ModuleDetails | null>(null);

    const getModule = () => {
      api.getModule(props.moduleId as number)
        .then((response) => {
          moduleInfo.value = response.data;
        });
    };

    const redirectToLesson = (slug: string) => {
      if (!moduleInfo.value) return;

      router.push({
        name: 'lesson',
        params: {
          moduleId: moduleInfo.value.module.id.toString(10),
          lessonSlug: slug,
        },
      });
    };

    getModule();

    return {
      moduleInfo,

      redirectToLesson,
    };
  },
});
