



























































































































































































































































































import { defineComponent } from '@vue/composition-api';
import ModuleDisplay from '@/components/ModuleDisplay.vue';
import useStore from '@/store';
import api from '@/api';

export default defineComponent({
  name: 'Index',
  components: {
    ModuleDisplay,
  },
  metaInfo: {
    title: 'Accueil',
  },
  setup() {
    const store = useStore();

    const startSubscription = (price: string) => {
      if (store.user && store.user.subscription.is_subscribed) {
        api.getStripePortal()
          .then((response) => {
            window.location.href = response.data.url;
          });
        return;
      }
      api.createSubscription(price);
    };

    return {
      store,

      startSubscription,
    };
  },
});
