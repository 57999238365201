import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { createRouter } from 'vue2-helpers/vue-router';
import useStore from '@/store';
import adminRoutes from './admin';

import index from '../views/index.vue';
import login from '../views/login.vue';
import logout from '../views/logout.vue';
import register from '../views/register.vue';
import profile from '../views/profile.vue';
import subSuccess from '../views/sub-success.vue';
import modules from '../views/modules.vue';
import lessons from '../views/lessons.vue';
import lesson from '../views/lesson.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: index,
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    meta: {
      auth: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      auth: false,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: logout,
    meta: {
      auth: true,
    },
  },

  {
    path: '/profile',
    name: 'profile',
    component: profile,
    meta: {
      auth: true,
    },
  },
  {
    path: '/subscribe/success',
    name: 'subscribe-success',
    component: subSuccess,
    meta: {
      auth: true,
    },
  },

  {
    path: '/modules',
    name: 'modules',
    component: modules,
    meta: {
      auth: true,
      premium: true,
    },
  },
  {
    path: '/modules/:moduleId/lessons',
    name: 'lessons',
    component: lessons,
    props(route) {
      const { params } = route;

      return {
        moduleId: parseInt(params.moduleId, 10),
      };
    },
    meta: {
      auth: true,
      premium: true,
    },
  },
  {
    path: '/modules/:moduleId/lessons/:lessonSlug',
    name: 'lesson',
    props: true,
    component: lesson,
    meta: {
      auth: true,
      premium: true,
    },
  },

  ...adminRoutes,
];

const router = createRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
      };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});

// eslint-disable-next-line import/no-mutable-exports
export let start!: (value: unknown) => void;
const startPromise = new Promise((resolve) => { start = resolve; });

router.beforeEach(async (to, from, next) => {
  await startPromise;

  if (to.meta !== undefined) {
    const store = useStore();

    // Check if the user is logged in
    if (to.meta && to.meta.auth !== undefined) {
      // User is not connected in a user restricted route
      if (to.meta.auth === true && store.user === null) {
        next(from.path || { name: 'login' });
        return;
      }
      if (to.meta.auth === false && store.user !== null) {
        next(from.path || { name: 'index' });
        return;
      }
    }

    // Check user subscription
    if (to.meta.premium && !store.user?.is_admin && (!store.user || !store.user.subscription.is_subscribed)) {
      next(from.path || { name: 'index', hash: '#subscriptions' });
      return;
    }

    // Check admin permission
    if (to.meta.admin && !(store.user && store.user.is_admin)) {
      next(from.path || { name: 'index' });
      return;
    }
  }

  next();
});

export default router;
