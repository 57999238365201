<template>
  <main class="container mt-8">
    <header class="row align-items-center">
      <h1 class="mb-1">{{ user.name }}</h1>

      <button v-if="user.subscription.is_subscribed" role="navigation" class="btn btn-sm btn-primary ml-auto" @click="accessStipePortal">
        Gérer mon abonnement
      </button>
      <router-link v-else :to="{ name: 'index', hash: '#subscriptions' }" class="btn btn-sm btn-primary ml-auto">
        Choisir un abonnement
      </router-link>
    </header>

    <section class="row mt-5">
      <div class="col-6">
        <p class="label mb-0 text-secondary">Adresse mail</p>
        <p>
          {{ user.email }}
          <span class="badge bg-warning text-white">Non vérifiée</span>
        </p>
      </div>

      <div class="col-6">
        <p class="label mb-0 text-secondary">Date d'inscription</p>
        <p>
          {{ format(new Date(user.created_at), 'dd/MM/yyy à HH:mm') }}
        </p>
      </div>
    </section>

    <section class="row mt-3">
      <div class="col-6">
        <p class="label mb-0 text-secondary">Abonnement actuel</p>
        <p>
          {{ subscriptionName }}
        </p>
      </div>
    </section>
  </main>
</template>

<script>
import { ref, computed, defineComponent } from '@vue/composition-api';
import { format } from 'date-fns';
import useStore from '@/store';
import api from '@/api';

export default defineComponent({
  name: 'Profile',
  metaInfo: {
    title: 'Profil',
  },
  setup() {
    const store = useStore();

    const isBusy = ref(false);
    const user = computed(() => store.user);

    const subscriptionName = computed(() => {
      if (!user.value.subscription.is_subscribed) {
        return 'Aucun';
      }
      return user.value.subscription.week ? 'Hebdomadaire' : 'Mensuel';
    });

    const subscribe = () => {
      api.createSubscription('week')
        .then((response) => {
          window.location.href = response.data.url;
        });
    };

    const accessStipePortal = () => {
      api.getStripePortal()
        .then((response) => {
          window.location.href = response.data.url;
        });
    };

    return {
      isBusy,
      user,
      subscriptionName,

      subscribe,
      accessStipePortal,
      format,
    };
  },
});
</script>
