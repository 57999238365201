import axios from 'axios';
import Lesson from './types/Lesson';
import Module, { ModuleDetails } from './types/Module';
import User from './types/User';

const $axios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-type': 'application/json',
  },
});

export default {
  getCsrfCookie() {
    return $axios.get('/sanctum/csrf-cookie');
  },

  login(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.getCsrfCookie()
        .then(() => {
          $axios.post('/login', {
            email,
            password,
          })
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  },

  logout() {
    return $axios.post('/logout');
  },

  register(user: unknown) {
    return new Promise((resolve, reject) => {
      this.getCsrfCookie()
        .then(() => {
          $axios.post('/register', user)
            .then(resolve)
            .catch(reject);
        })
        .catch(reject);
    });
  },

  me() {
    return $axios.get<User>('/user');
  },

  createSubscription(price: string) {
    return new Promise((resolve, reject) => {
      $axios.post('/subscribe', { price })
        .then((response) => {
          window.location.href = response.data.url;
          resolve(response);
        })
        .catch(reject);
    });
  },

  getStripePortal() {
    return $axios.get('/portal');
  },

  getModules() {
    return $axios.get<Module[]>('/modules');
  },

  getModule(moduleId: number) {
    return $axios.get<ModuleDetails>(`/modules/${moduleId}`);
  },

  createModule(module: Partial<Module>) {
    return $axios.post('/modules', module);
  },

  updateModule(module: Module) {
    return $axios.put<ModuleDetails>(`/modules/${module.id}`, module);
  },

  deleteModule(moduleId: number) {
    return $axios.delete(`/modules/${moduleId}`);
  },

  getLesson(lessonSlug: string) {
    return $axios.get<Lesson>(`/lessons/${lessonSlug}`);
  },

  createLesson(lesson: FormData) {
    return $axios.post<Lesson>('/lessons', lesson);
  },

  updateLesson(lesson: Lesson) {
    return $axios.put<Lesson>(`/lessons/${lesson.slug}`, lesson);
  },

  deleteLesson(lessonSlug: string) {
    return $axios.delete(`/lessons/${lessonSlug}`);
  },
};
