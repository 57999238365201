



















import { computed, ref } from '@vue/composition-api';
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed';
import api from '@/api';
import Lesson from '@/types/Lesson';

export default {
  name: 'Lesson',
  metaInfo: {
    title: 'Leçon',
  },
  components: {
    VuePdfEmbed,
  },
  props: {
    lessonSlug: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const lesson = ref<Lesson | null>(null);

    const getLesson = () => {
      api.getLesson(props.lessonSlug as string)
        .then((response) => {
          lesson.value = response.data;
        });
    };

    const pdfContent = computed(() => ({
      data: atob(lesson.value?.content ?? ''),
    }));

    const breadcrumb = computed(() => {
      if (!lesson) return [];

      return [
        {
          text: 'Modules',
          to: { name: 'modules' },
        },
        {
          text: lesson.value?.module.name,
          to: {
            name: 'lessons',
            params: {
              moduleId: lesson.value?.module.id,
            },
          },
        },
        {
          text: lesson.value?.title,
          active: true,
        },
      ];
    });

    getLesson();

    return {
      lesson,
      pdfContent,
      breadcrumb,
    };
  },
};
