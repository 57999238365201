import modules from '@/views/admin/modules.vue';
import modulesCreate from '@/views/admin/moduleCreate.vue';
import lessons from '@/views/admin/lessons.vue';
import lessonsCreate from '@/views/admin/lessonCreate.vue';

export default [
  {
    name: 'admin-modules',
    path: '/admin/modules',
    component: modules,
    meta: {
      admin: true,
    },
  },
  {
    name: 'admin-modules-create',
    path: '/admin/module/new',
    component: modulesCreate,
    meta: {
      admin: true,
    },
  },
  {
    name: 'admin-lessons',
    path: '/admin/module/:moduleId/lessons',
    component: lessons,
    meta: {
      admin: true,
    },
    props: true,
  },
  {
    name: 'admin-lessons-create',
    path: '/admin/module/:moduleId/lessons/new',
    component: lessonsCreate,
    meta: {
      admin: true,
    },
    props: true,
  },
];
